<template>
	<app tabbar background="#fff">
		<ul class="flex-wrap">
			<router-link v-for="(item, i) in navList" :key="i" :to="item.path || ''">
				<li class="flex-column flex-center">
					<div class="icon flex-center" :style="{'background': item.color}"><i class="tf" :class="item.icon"></i></div>
					<p>{{item.mobileTitle || item.title}}</p>
				</li>
			</router-link>
			<router-link to="/message_list">
				<li class="flex-column flex-center">
					<div class="icon flex-center" style="background: #3296FA">
						<i class="tf tf-menu-message"></i>
						<ins v-if="messageTotal > 0">{{messageTotal}}</ins>
					</div>
					<p>消息中心</p>
				</li>
			</router-link>
		</ul>
	</app>
</template>

<script>
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		computed: {
			...mapState(['messageTotal']),
			...mapGetters(['navList'])
		},
		
		mounted() {
			this.$store.dispatch('updateMessageTotal');
		}
	};
</script>

<style lang="stylus" scoped>
	body {
		background: #fff;
	}
	
	ul {
		padding: .16rem 0;
		
		li {
			width: 25vw;
			height: 2rem;
			
			.icon {
				position: relative;
				width: .96rem;
				height: .96rem;
				border-radius: .2rem;
				margin-bottom: .22rem;
				font-size: .46rem;
				color: #fff;
				
				ins {
					position: absolute;
					left: .72rem;
					bottom: .8rem;
					box-sizing: border-box;
					min-width: .32rem;
					height: .32rem;
					border-radius: .16rem;
					padding: 0 .08rem;
					line-height: .34rem;
					font-size: .24rem;
					text-align: center;
					color: #fff;
					background: #E63633;
				}
			}
			
			p {
				font-size: .28rem;
				color: #363A44;
			}
		}
	}
</style>